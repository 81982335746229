.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.condition-group {
  padding: 10px;
  margin: 30px 0;
}

.condition-row {
  padding: 10px;
  margin: 5px 0;
}

.condition-group {
  position: relative;
}

.condition-group-container .and-connector {
  font-weight: bold;
  text-transform: uppercase;
  color: #808080;
  text-align: left;
  position: relative;
  display: block;
  width: 100px;
  text-align: center;
  font-size: 20px;
}

.condition-group-container:last-child .condition-group::after,
.and-connector::before,
.and-connector::after {
  display: block;
  content: '';
  position: absolute;
  left: 50px;
  width: 2px;
  height: 25px;
  background-color: #c0c0c0;
}

.and-connector::before {
  top: -30px;
}

.condition-group-container:last-child .condition-group::after {
  bottom: -30px;
  height: 30px;
}

.and-connector::after {
  bottom: -30px;
}

.condition-group-container:first-of-type .and-connector:first-of-type {
  display: none;
}

.add-button {
  display: flex;
}

.add-button button {
  width: 100px; 
  height: 50px;
  margin: 0;
  font-size: 16px;
}

.or {
  color: #0969da;
  font-weight: bold;
  text-transform: uppercase;
  margin-right: 10px;
  font-size: 20px;
}

.add-button button {
  color: #0969da;
  font-weight: bold;
  text-transform: uppercase;
  padding: 5px 10px;
}